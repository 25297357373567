<!-- ATTENTION: SVG files are not allowed to run through the CDN -->
<template>
    <nuxt-resource
        :lazy="lazy"
        :full-u-r-l="true"
        :src="srcString"
        :srcset="srcSet"
        :sizes="sizesString"
        :alt="alt"
        :width="width"
        :height="height"
        :ratio="ratio"
        @error="setFallback"
    ></nuxt-resource>
</template>

<script>
export default {
    props: {
        lazy: {
            type: Boolean,
            required: false,
            default: true,
        },
        src: {
            type: String,
            required: true,
        },
        rawSrc: {
            type: Boolean,
            required: false,
        },
        width: {
            type: Number,
            required: false,
        },
        height: {
            type: Number,
            required: false,
        },
        fit: {
            type: String,
            validator: value => {
                return ['scale-down', 'contain', 'cover', 'crop'].includes(value);
            },
        },
        quality: {
            type: Number,
            required: false,
            default: 85,
        },
        responsiveWidths: {
            type: Array,
            required: false,
        },
        responsiveSizes: {
            type: Array,
            required: false,
        },
        sizes: {
            type: String,
            required: false,
        },
        fallBack: {
            type: String,
            required: false,
        },
        alt: {
            type: String,
            required: false,
            default: '',
        },
        resource: {
            type: Boolean,
            required: false,
            default: false,
        },
        ratio: {
            type: String,
            default: undefined,
            required: false,
        },
    },

    data() {
        return {
            error: false,
        };
    },

    computed: {
        srcSet() {
            if (this.responsiveSizes) {
                const sources = this.responsiveSizes.map(size =>
                    this.formatSrcSetItem(this.getOptions(size.width), size.src, size.width)
                );
                return sources.join('');
            }
            if (this.responsiveWidths) {
                const sources = [...this.responsiveWidths, this.width].map(width =>
                    this.formatSrcSetItem(this.getOptions(width), this.src, width)
                );
                return sources.join('');
            }
            return this.formatSrcSetItem(this.getOptions(this.width), this.src, this.width);
        },
        sizesString() {
            if (this.sizes) {
                return this.sizes;
            } else if (this.width) {
                return this.width + 'px';
            }
            return null;
        },
        srcString() {
            if (this.rawSrc) {
                return this.src;
            }

            if (!this.error) return this.formatSrc(this.getOptions(this.width), this.src);

            const domain = 'https://images.dundle.com/resources/';
            return this.fallBack.startsWith('http') ? this.fallBack : domain + this.fallBack;
        },
    },

    methods: {
        formatSrc(options, src) {
            return `${this.$config.images.baseUrl}/resources/${src}?${options}`;
        },
        formatSrcSetItem(options, src, width) {
            return `${this.$config.images.baseUrl}/resources/${src}?${options} ${width}w`;
        },
        getOptions(width) {
            // It is always benificial to use format auto, as browsers that do not support it will get a png or jpg but modern browser will get webp
            let options = 'format=auto';
            if (width) {
                options += '&width=' + width;
            }
            if (this.height) {
                options += '&height=' + this.height;
            }
            if (this.fit) {
                options += '&fit=' + this.fit;
            }
            if (this.quality) {
                options += '&quality=' + this.quality;
            }
            return options;
        },
        setFallback(event) {
            if (this.fallBack) {
                event.target.src = this.fallBack;
                this.error = true;
            }
        },
    },
};
</script>
