<template>
    <input
        v-if="!validation"
        :id="id"
        class="ui-form-input"
        :value="value"
        v-bind="attrs"
        :placeholder="placeholder"
        @input="input"
        v-on="listeners"
    />
    <div v-else class="ui-form-input-wrapper w-100">
        <div class="icon-wrapper">
            <input
                :id="id"
                class="ui-form-input"
                :class="{ error: hasError, warning: hasWarning || showMessage, valid: isValid && !showMessage }"
                :value="value"
                v-bind="attrs"
                :validation="validation"
                :placeholder="placeholder"
                @input="input"
                @change="validate"
                @blur="validate"
                v-on="listeners"
            />
            <div v-if="isDirty" class="validation-icons">
                <UIIcon
                    v-if="isValid && !showMessage"
                    name="check-circle"
                    class="valid-icon"
                    aria-hidden="true"
                    tabindex="-1"
                />
                <UIIcon v-if="hasError" name="exclamation-circle" class="error-icon" aria-hidden="true" tabindex="-1" />
                <UIIcon
                    v-if="hasWarning || showMessage"
                    name="exclamation-triangle"
                    class="warning-icon"
                    aria-hidden="true"
                    tabindex="-1"
                />
            </div>
        </div>

        <span v-if="hasError" class="error-text small">
            <slot name="error-message" />
        </span>
        <span v-if="hasWarning || showMessage" class="warning-text small">
            <slot v-if="hasWarning" name="warning-message" />
            <slot v-if="showMessage" name="checkout-message" />
        </span>
    </div>
</template>

<script>
import { UIIcon } from '@dundle/ui/components';
import ValidationMixin from '~/mixins/ValidationMixin';
export default {
    components: {
        UIIcon,
    },
    mixins: [ValidationMixin],
    inheritAttrs: false,
    // needs id to bind label in parent and validation message to input
    props: ['value', 'validation', 'id', 'placeholder'],

    computed: {
        listeners() {
            const { input, ...listeners } = this.$listeners;
            return listeners;
        },
        attrs() {
            const { ...attrs } = this.$attrs;
            return attrs;
        },
    },

    methods: {
        input(e) {
            this.$emit('input', e.target.value);
            if (this.isDirty) this.validate(e);
        },
        validate(e) {
            this.validateOnChange({ value: e.target.value });
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-form-input {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    color: $color-grey-6;
    background-color: $color-form-bg;
    background-clip: padding-box;
    border: 1px solid $color-grey-3;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    overflow: visible;

    // fix for iOS zoom
    height: 3.3333333rem;
    line-height: 2.3333333rem;
    font-size: 16px !important;
    padding: 0.5rem 1rem;

    // Hide arrows
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    & {
        -moz-appearance: textfield;
    }

    &:focus {
        z-index: 1;
        color: $color-grey-6;
        background-color: $color-form-bg;
        border-color: lighten($color-primary, 30%);
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba($color-primary, 0.25);
    }

    &::placeholder {
        color: $color-grey-4;
    }

    // on focus, whole text is shown
    // this is a known problem, also on MDN
    &:placeholder-shown {
        text-overflow: ellipsis;
    }

    &[disabled],
    &[readonly] {
        background-color: rgba(0, 0, 0, 0.05);
        opacity: 1;
    }

    @media (min-width: 1024px) {
        height: 3rem;
        line-height: 2rem;
    }

    &[type='search']::-webkit-search-cancel-button {
        cursor: pointer;
    }

    &.error {
        border-color: $color-error;
        background-color: $color-error-bg;
    }

    &.warning {
        border-color: $color-warning;
    }

    &.valid {
        border-color: $color-valid;
        background-color: $color-valid-bg;
    }
}
.error-text {
    color: $color-error;
}
.warning-text {
    color: $color-warning;
}

.validation-icons {
    position: absolute;
    line-height: 1.75;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
        margin-right: 0.75rem;
    }
    .warning-icon {
        color: $color-warning;
    }
    .error-icon {
        color: $color-error;
    }
    .valid-icon {
        color: $color-valid;
    }
}
</style>
