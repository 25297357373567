<template>
    <div class="x-search" :class="[rootClasses, variant]" @click.stop>
        <!-- The empty action is for displaying "search" when using the search input on IOS -->
        <form action=".">
            <div class="search-input-container">
                <UIRipple
                    v-if="variant !== 'homepage'"
                    color="black"
                    :opacity="0.15"
                    :speed="3"
                    class="d-block d-md-none close-icon"
                    @click.native="closeSearch"
                >
                    <UIIcon :name="['fal', 'arrow-left']" />
                </UIRipple>
                <label for="search-input" class="sr-only">{{ translate('search.placeholder') }}</label>
                <UIFormInput
                    id="search-input"
                    ref="input"
                    v-model="query"
                    class="search-input"
                    :class="variant"
                    type="search"
                    :placeholder="translate('search.placeholder')"
                    autocomplete="off"
                    @focus.native="onFocus"
                    @keydown.esc="closeSearch"
                    @keydown.down="moveResultDown"
                    @keydown.up="moveResultUp"
                    @keydown.enter="goToResult(activeResult)"
                    @keydown.tab="closeSearch"
                ></UIFormInput>

                <div v-if="!query" class="button-container" @click="goToResult(activeResult)">
                    <UIIcon class="icon" :name="['far', 'search']" />
                </div>
                <div v-if="query" class="button-container" @click="removeSearchQuery()">
                    <UIIcon class="icon remove-search" :name="['fal', 'times']" />
                </div>
            </div>
        </form>

        <div v-if="query" class="results" :class="variant">
            <section v-for="(result, index) in results" :key="index">
                <header v-if="result.items.length > 0">
                    {{ translate(result.type.toLowerCase()) }}
                </header>
                <a
                    v-for="(item, itemIndex) in result.items"
                    :key="itemIndex"
                    class="result d-flex align-items-center"
                    :href="item.url"
                    :class="{ active: activeResult === item }"
                    @click.prevent.stop="onClickItem(item)"
                >
                    <div v-if="item.image" class="image mr-3" :class="item.type">
                        <UIImage :src="item.image" :resource="true" :alt="translateParams(item.title)" :width="32" />
                    </div>
                    <span class="flex-grow-1 pr-3">{{ translateParams(item.title) }}</span>
                    <UIIcon class="icon" :name="icons[item.type]"></UIIcon>
                </a>
            </section>
            <div v-if="results.length === 0" class="no-results">
                {{ translate('empty') }}
            </div>
            <div v-else class="all-results" @click="goToResult(null)">
                {{ translate('allResults') }} <UIIcon class="arrow" :name="['fal', 'arrow-right']"></UIIcon>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { UIFormInput, UIIcon, UIRipple, UIImage } from '@dundle/ui/components';
import { debounce } from '@dundle/utils/function';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIIcon,
        UIFormInput,
        UIRipple,
        UIImage,
    },

    mixins: [TranslationMixin],

    props: {
        variant: {
            type: String,
            default: 'standard',
        },
    },

    data() {
        return {
            activeResult: null,
            searchResults: [],
            query: '',
            icons: {
                blog: ['fal', 'newspaper'],
                brand: ['fal', 'arrow-right'],
                product: ['fal', 'arrow-right'],
                page: ['fal', 'arrow-right'],
            },
        };
    },

    computed: {
        rootClasses() {
            return {
                active: this.active && !this.activeModal,
                'with-results': this.query,
            };
        },

        ...mapGetters({
            active: 'ui/searchIsActive',
            activeModal: 'ui/activeModal',
            overlayElement: 'ui/overlayElement',
            activeCategories: 'data/getActiveCategories',
        }),

        results() {
            // fixed order of result type
            const results = [
                {
                    type: 'brand',
                    items: [],
                },
                {
                    type: 'product',
                    items: [],
                },
                {
                    type: 'page',
                    items: [],
                },
            ];

            this.searchResults.forEach(record => {
                const type = results.find(c => c.type === record.type);
                if (type && type.items.length < 4) type.items.push(record);
                if (!type) console.warn('Product not shown in list, this type is not defined:', record.type);
            });
            // sort search results
            return results;
        },

        flatResults() {
            return this.results.flatMap(x => x.items);
        },
    },

    watch: {
        query(value) {
            this.fetchSearchResults(value);
        },
    },

    methods: {
        ...mapActions({
            showMenuOverlay: 'ui/showMenuOverlay',
            hideOverlay: 'ui/hideOverlay',
            showSearch: 'ui/showSearch',
            hideSearch: 'ui/hideSearch',
            hideSubmenu: 'ui/hideSubmenu',
        }),

        onFocus() {
            this.$refs.input.$el.select();
            this.hideSubmenu();
            this.showSearch();
            this.showMenuOverlay();
        },

        forceFocus() {
            this.showSearch();
            this.$refs.input.$el.focus();
        },

        closeSearch(e) {
            // when tab is pressed and there is a query, don't close
            if (e?.keyCode === 9 && this.query) {
                return false;
            }

            this.hideSearch();
            this.hideOverlay();
            this.activeResult = null;
            this.$refs.input.$el.blur();
        },

        onClickItem(item) {
            window.location.replace(item.url);
        },

        removeSearchQuery() {
            this.query = '';
            this.closeSearch();
        },

        moveResultDown() {
            if (this.activeResult) {
                const index = this.flatResults.indexOf(this.activeResult);
                if (index + 1 !== this.flatResults.length) this.activeResult = this.flatResults[index + 1];
            } else {
                this.activeResult = this.flatResults[0];
            }
        },

        moveResultUp() {
            if (this.activeResult) {
                const index = this.flatResults.indexOf(this.activeResult);
                if (index !== 0) this.activeResult = this.flatResults[index - 1];
            }
        },

        goToResult(result) {
            if (this.variant === 'homepage') {
                this.$gtm?.push({ event: 'homepage_search' });
            } else {
                this.$gtm?.push({ event: 'searchbar_search' });
            }
            this.closeSearch();
            if (result && result.url) {
                this.$router.push({ path: result.url });
            } else {
                this.$router.push(this.$locale.path({ name: 'search', query: { q: this.query } }));
            }
        },

        fetchSearchResults: debounce(async function (value) {
            this.$analytics?.search(value);
            this.$gtm?.push({ event: 'search', value });

            this.activeResult = null;
            const result = await this.$api.search.searchByQuery({
                query: { q: value },
                categories: this.activeCategories,
            });

            const results = [];
            Object.keys(result.hits).forEach(key => results.push(result.hits[key]));
            this.searchResults = results.flat().map(product => {
                if (product.modelType === 'page') {
                    return {
                        // Refactor this when multiple pages are added
                        title: '{{loyaltyName}}',
                        url: '/' + product.id,
                        type: 'page',
                    };
                }
                if (product.modelType === 'article') {
                    return {
                        ...product,
                        type: 'blog',
                    };
                }

                const isBrand = product.modelType === 'brand';
                const type = product.modelType;
                return {
                    type,
                    image: isBrand ? this.$brand.getImage200(product) : this.$product.getImage200(product),
                    url: isBrand ? this.$brand.getPath(product) : this.$product.getPath(product),
                    score: product.popularity,
                    title: isBrand ? this.$brand.getName(product) : this.$product.getName(product),
                    category: product.category,
                };
            });
        }, 800),
    },
};
</script>
<style lang="scss" scoped>
.x-search {
    text-align: center;
    flex: 1;
    // Mobile
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    @media screen and (max-width: 767px) {
        background: $color-grey-1;
    }

    &.active {
        bottom: 0;
        height: 100vh;

        @media screen and (min-width: 768px) {
            height: auto;
        }
    }

    @media screen and (min-width: 768px) {
        overflow: visible;
        max-width: 400px; // Fix this
        position: relative;
        margin: 0.5rem 0.25rem;
        border-radius: $search-border-radius;
        background-color: transparent;
    }

    &.active {
        z-index: 10;

        @media screen and (min-width: 768px) {
            border-radius: $search-border-radius;
        }

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 1px 4px $color-grey-7;
    }

    .search-input-container {
        position: relative;
        width: 100%;
        border-bottom: 1px solid $color-grey-3;
        height: 4rem;

        .close-icon {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding: 0.5rem 1rem;
            font-size: 1.25rem;
            color: #666;
        }

        @media screen and (max-width: 767px) {
            background: $color-search-bar-mobile;
            padding-left: 2rem;
        }

        & > * {
            line-height: 3rem !important;
            height: 4rem;
        }

        @media screen and (min-width: 768px) {
            height: auto;
            border-bottom: none;

            & > * {
                line-height: 2.5rem !important;
                height: 3rem;
            }

            & > input {
                line-height: 2rem;
            }
        }
    }

    .search-input {
        margin: 0 auto;
        width: 100% !important;
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding-left: 1rem;
        padding-right: 2.1rem;

        &:focus {
            background-color: transparent;
        }

        @media screen and (min-width: 768px) {
            border-radius: $search-border-radius;
            background-color: $color-search-bar;
            border: 1px solid $color-search-bar-border;
            color: $color-search-text;
        }

        &::placeholder {
            color: $color-search-placeholder;
        }

        &:focus {
            box-shadow: none !important;
        }
    }

    &.active input {
        z-index: 1;
        opacity: 1;

        @media screen and (min-width: 768px) {
            border-radius: $search-border-radius;
            background-color: $color-bg;
            border: none;

            &:focus {
                background-color: $color-bg;
            }
        }
        color: $color-grey-6;
    }

    &.active.with-results {
        @media screen and (min-width: 768px) {
            border-radius: $border-radius-default;
        }

        input {
            border-radius: $border-radius-default;
        }
    }

    &.active input::placeholder {
        color: $color-grey-3;
    }

    .icon {
        font-size: 1.25rem;
        color: $color-search-text;
    }
    img.icon {
        height: 1.25rem;
    }

    .button-container {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0.5rem 1rem;

        &:hover {
            cursor: pointer;
        }

        .remove-search {
            color: $color-primary;
        }
    }

    &.active .icon {
        color: $color-grey-3;
    }

    .results {
        background: $color-bg;
        overflow: hidden;
        position: fixed;
        width: 100%;
        left: 0;
        top: 4rem;
        height: 0;
        transform-origin: top;
        text-align: left;
        opacity: 0;
        transform: scale(1, 0.7);
        transition: opacity 1s cubic-bezier(0.1, 1.45, 0.35, 1), transform 0.25s cubic-bezier(0.1, 1.45, 0.35, 1);

        .correction {
            padding: 0.5rem 1rem;
            line-height: 1.5rem;
            color: darken($color-grey-1, 20%);
            b {
                color: $color-text-bold;
            }
        }

        .no-results {
            padding: 0.5rem 1rem;
            line-height: 1.5rem;
            color: $color-grey-6;

            @media screen and (max-width: 767px) {
                background: $color-grey-1;
            }
        }

        .all-results {
            background: $color-grey-2;
            border-top: 1px solid $color-grey-3;
            text-align: center;
            line-height: 2.3rem;
            color: $color-primary;
            font-size: 0.9rem;
            cursor: pointer;

            &:hover {
                background: $color-grey-3;
            }

            .arrow {
                margin: 0 0 0 0.5rem;
            }
        }

        & > section > header {
            margin-top: 0rem;
            font-size: 0.8rem;
            background: $color-grey-3;
            font-weight: bold;
            padding: 0;
            padding: 0.5rem 1rem;
            height: 1.8rem;
            line-height: 1rem;
            display: flex;
            align-items: center;

            & > span {
                display: inline-block;
                vertical-align: middle;
                flex: 1 1;
                margin-left: 1rem;
                content: ' ';
                width: 10px;
                height: 1px;
            }
        }

        & > section > .result {
            line-height: 2rem;
            padding: 0.5rem 1rem;
            color: $color-text-bold;

            .image {
                width: 2.15rem;
                min-width: 2.15rem;
                position: relative;
                line-height: initial;
                border: 1px solid $color-grey-3;
                border-radius: 3px;

                &:after {
                    height: 100%;
                    width: 100%;
                    border-radius: 3px;
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    img {
                        border-radius: 3px;
                    }
                }

                img {
                    width: 100%;
                }
            }

            span {
                ::v-deep em {
                    color: $color-primary;
                    font-style: normal !important;
                }

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            & + .result {
                border-top: 1px solid $color-grey-3;
            }

            &:hover {
                background-color: $color-grey-1;
                cursor: pointer;
                text-decoration: none;
            }

            &.active {
                background-color: $color-grey-2;
            }
        }
    }

    &.active.with-results .results {
        display: block;
        height: auto;
        opacity: 1;
        top: 4rem;
        transform: scale(1, 1);
        border-bottom: 1px solid $color-grey-3;

        @media screen and (min-width: 768px) {
            top: 3rem;
            border-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        &.active.with-results input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .results {
            position: absolute;
            top: 100%;
            left: 0;
            border-bottom-left-radius: 0.7333333333333333rem;
            border-bottom-right-radius: 0.7333333333333333rem;
            z-index: 0;
            transition: opacity 0.25s cubic-bezier(0.1, 1.45, 0.35, 1), transform 0.25s cubic-bezier(0.1, 1.45, 0.35, 1);
        }
    }
}

.x-search.homepage {
    max-width: 100%;
    border-radius: $border-radius-default;
    margin-top: 0.333rem;

    @media screen and (max-width: 768px) {
        overflow: visible;
        position: relative;
        margin: 0.5rem 0.25rem;
        background-color: transparent;
    }

    .search-input-container {
        border-radius: $border-radius-default;
        background-color: $color-grey-1;
        height: 3rem;
        padding-left: 0;

        @media screen and (max-width: 768px) {
            border: 1px solid $color-grey-2;
        }

        input.search-input.homepage {
            padding-left: 1rem;
            height: 3rem;

            @media screen and (min-width: 768px) {
                border-radius: $border-radius-default;
            }
        }
        .button-container {
            @media screen and (max-width: 768px) {
                display: flex;
                align-items: center;
            }
        }
    }

    &.active.with-results {
        .search-input-container {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            input.search-input.homepage {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .results.homepage {
        position: absolute;
        margin-left: 0;
        top: 3rem;
        border-bottom-left-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        @media screen and (min-width: 768px) {
            top: 2.666rem;
        }
    }
}
</style>
<i18n src="@dundle/locale/data/search.json"></i18n>
<i18n></i18n>
