import { render, staticRenderFns } from "./UIGridRow.vue?vue&type=template&id=faf1e562&scoped=true"
import script from "./UIGridRow.vue?vue&type=script&lang=js"
export * from "./UIGridRow.vue?vue&type=script&lang=js"
import style0 from "./UIGridRow.vue?vue&type=style&index=0&id=faf1e562&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faf1e562",
  null
  
)

export default component.exports