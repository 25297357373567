
export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

// https://stackoverflow.com/questions/35228052/debounce-function-implemented-with-promises
/**
 * Returns a promise that resolves when the debounced function is called
 */
export function debounceAsync(func, wait) {
	let timeout = null;
	return function() {
		const context = this, args = arguments;
		clearTimeout(timeout);
		
		return new Promise(resolve => {
			timeout = setTimeout(function() {
				resolve(func.apply(context, args));
			}, wait);
		})
	}
}