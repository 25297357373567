/*
    Frontend form validation
    author: Eline van Hooff
    Mixin for component scoped computed properties for validation

    Include this mixin in components where you need frontend validation (not backend validation)
    For adding new rules, use the the validation plugin
*/
export default {
    computed: {
        isValid() {
            return this.$validation.valid?.[this.id] === true || this.$validation.valid?.[this.validationId] === true;
        },
        hasError() {
            return (
                this.$validation.errors?.[this.id] === 'error' ||
                this.$validation.errors?.[this.validationId] === 'error'
            );
        },
        hasWarning() {
            return (
                this.$validation.errors?.[this.id] === 'warning' ||
                this.$validation.errors?.[this.validationId] === 'warning'
            );
        },
        showMessage() {
            return (
                this.$validation.errors?.[this.id] === 'message' ||
                this.$validation.errors?.[this.validationId] === 'message'
            );
        },
        isDirty() {
            return this.isValid || this.hasError || this.hasWarning || this.showMessage;
        },
    },
    methods: {
        /*
            Function for validating the form fields on input change.
            Use this function within your form field component.

            @param {id}         id of the form field
            @param {value}      value to be checked by the validation rule
            @param {validation} rule to be used, this rule should match the rule in the data validationRules defined in the plugin
        */
        validateOnChange({ id, value, validation }) {
            if (this.validation) {
                if (!id) id = this.id;
                if (!validation) validation = this.validation;
                this.$validation.validateOnChange({ id, value, validation });
            }
        },
    },
};
