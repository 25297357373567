<template>
    <b-row :class="[gutter + '-gutter', gutterLg + '-gutter-lg']" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </b-row>
</template>

<script>
export default {
    props: {
        gutter: {
            type: String,
            default: 'normal',
        },

        gutterLg: {
            type: String,
            default: 'default',
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin make-gutter($width) {
    margin-right: -$width !important;
    margin-left: -$width !important;

    ::v-deep .ui-col {
        padding-right: $width !important;
        padding-left: $width !important;
    }
}

.small-gutter {
    @include make-gutter(7.5px);
}

@media screen and (min-width: 1024px) {
    .small-gutter-lg {
        @include make-gutter(7.5px);
    }

    .normal-gutter-lg {
        @include make-gutter(15px);
    }
}
</style>
