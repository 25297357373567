<template>
    <FontAwesomeIcon :color="color" :icon="name" class="x-icon" />
</template>

<script>
export default {
    // use the hydrate prop when there is javascript triggering a change of icon on page load and it does not work
    props: ['name', 'color', 'hydrate'],
};
</script>

<style lang="scss" scoped>
.x-icon {
    display: inline-block;
    font-size: inherit;
    overflow: visible;
    vertical-align: -0.125em;
}
</style>
